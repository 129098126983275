<template>
	<div>
		<div class="therapy-record-container grid-content bg-purple">
			<div ref="therapyRecordBar" class="therapy-record-bar"
					 style=" height:384px"></div>
		</div>
	</div>
</template>

<script>
import {getReportDiagnosis} from "@/api/dashboard";
export default {
  name: "EchartBarPie",
	created () {
    this._getReportDiagnosis({type: 'month'})
  },
  mounted() {
    this._initChart()	//初始化Chart实例
    window.addEventListener("resize", this.chartInstance.resize);	// 监听页面大小改变
  },
  watch:{	// 监视数据改变,重新绘制Chart
    barDataDiagnosis: {
      handler(newValue, oldValue) {
        this.barDataDiagnosisX = []
        this.barDataDiagnosisPresY = []
        this.barDataDiagnosisEmrY = []
        if (Object.keys(newValue).length) {
          newValue.pres.forEach(item => {
            this.barDataDiagnosisX.push(item.day)
            this.barDataDiagnosisPresY.push(item.count)
          })
          newValue.emr.forEach(item => {
            this.barDataDiagnosisEmrY.push(item.count)
          })
        }
        this.$nextTick(() => {
          this.chartInstance.dispose()
          this._initChart()
        })
      },
      immediate: true,
      deep: true
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.chartInstance)	// 页面关闭时候移除监听
  },
  data() {
    return {
      barDataDiagnosis: {},
      barDataDiagnosisX: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子'],	// 横轴数据
      barDataDiagnosisEmrY: [],	// 纵轴数据
      barDataDiagnosisPresY: [],
    }
  },
  methods: {
    _getReportDiagnosis(params) {
      this.listLoading = true
      console.log(params)
      console.log('_getReportDiagnosis_getReportDiagnosis_getReportDiagnosis')
      getReportDiagnosis(params).then(response => {
        let data = response
        if (data.code === 200) {
          this.barDataDiagnosis = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _initChart() {
      const initOption = {
        color: ['#2362fb', '#fcc810'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: ['电子处方' , '电子病历' ],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          // data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
          data: this.barDataDiagnosisX
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '电子处方',
          type: 'bar',
          barWidth: '20%', //柱图宽度
          data:  this.barDataDiagnosisPresY
        },
          {
            name: '电子病历',
            type: 'bar',
            barWidth: '20%', //柱图宽度
            data: this.barDataDiagnosisEmrY
          }
        ]
      }
      this.chartInstance = this.$echarts.init(this.$refs.therapyRecordBar)	// 通过DOM获取Echart实例
      this.chartInstance.showLoading()
      this.chartInstance.setOption(initOption)	// 初始化数据
      this.chartInstance.hideLoading();
    },
  }
}
</script>

<style scoped>

</style>
