import request from '@/utils/request'


// 首页数据 - 医院简报
export function getReportHospital(params) {
  return request({
    url: '/admin/report/hospital',
    method: 'GET',
    params
  })
}

// 首页数据 - 问诊记录(柱状图)
export function getReportDiagnosis(params) {
  return request({
    url: '/admin/report/diagnosis',
    method: 'GET',
    params
  })
}

// 首页数据 - 运营数据(table表)
export function getReportOperate(params) {
  return request({
    url: '/admin/report/operate',
    method: 'GET',
    params
  })
}


// 首页数据 - 问诊来源/患者来源 饼状图
export function getReportFromSource(params) {
  return request({
    url: '/admin/report/fromSource',
    method: 'GET',
    params
  })
}

